.clickers-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(15, 1fr);
}

.clicker-wookie {
  grid-column: 4 / 10;
  grid-row: 2 / 11;
}

.helper-porg {
  grid-column: 1 / 4;
  grid-row: 8 / 11;
}

.helper-ewok {
  grid-column: 2 / 5;
  grid-row: 11 / 14;
}

.helper-c3po {
  grid-column: 4 / 7;
  grid-row: 13 / 16;
}

.helper-r2d2 {
  grid-column: 7 / 10;
  grid-row: 13 / 16;
}

.helper-xwing {
  grid-column: 9 / 12;
  grid-row: 11 / 14;
}

.helper-falcon {
  grid-column: 10 / 13;
  grid-row: 8 / 11;
}

.inactive-helpers {
  filter: saturate(0) opacity(60%);
}

.helpers:active {
  animation: zoomOff;
  animation-duration: 0.1s;
}

@media screen and (min-width: 800px) {
  .clickers-container {
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(12, 1fr);
  }

  .clicker-wookie {
    grid-column: 5 / 7;
    grid-row: 3 / 11;
  }

  .helper-porg {
    grid-column: 3 / 4;
    grid-row: 2 / 5;
  }

  .helper-ewok {
    grid-column: 2 / 3;
    grid-row: 6 / 10;
  }

  .helper-c3po {
    grid-column: 3 / 4;
    grid-row: 10 / 14;
  }

  .helper-r2d2 {
    grid-column: 8 / 9;
    grid-row: 2 / 5;
  }

  .helper-xwing {
    grid-column: 9 / 10;
    grid-row: 6 / 9;
  }

  .helper-falcon {
    grid-column: 8 / 9;
    grid-row: 10 / 14;
  }
}
