@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@100;200;300;400;500&display=swap");

@font-face {
  font-family: "StarJedi";
  src: url("./assets/Fonts/star_jedi/Starjedi.ttf");
}

:root {
  --main-font: "StarJedi";
  --second-font: "Libre Franklin";
  --main-color: #3c415c;
  --background-counters-color: rgba(247, 247, 247, 0.454);
}

@keyframes zoomOff {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(0.8);
  }
}

@keyframes byebye {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-40vh);
  }
}

* {
  margin: 0;
  padding: 0;
}

body {
  width: 100vw;
  height: 100vh;
}

#root {
  height: 100%;
  width: 100%;
}

.App {
  height: 100%;
  width: 100%;
  background-image: url(./assets/Images/background-mobile.jpg);
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

header {
  height: 20%;
  padding: 2%;
  display: flex;
  justify-content: space-between;
}

main {
  width: 100%;
  display: flex;
  align-items: center;
}

footer {
  height: 15%;
}

img {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

@media screen and (min-width: 800px) {
  .App {
    background-image: url(./assets/Images/background-desktop.jpg);
  }

  header {
    height: 10%;
  }

  main {
    height: 70%;
  }
}
