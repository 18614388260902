.message {
  z-index: 3;
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.message p {
  background: black;
  border: 2px solid white;
  width: 60%;
  height: 20%;
  font-family: var(--main-font);
  color: rgb(255, 230, 68);
  font-size: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  text-align: center;
}
