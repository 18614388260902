.clicker-wookie {
  width: 100%;
  margin: auto;
}

.clicker-wookie:active {
  animation: zoomOff;
  animation-duration: 0.1s;
}

.chewie {
  width: 100%;
}

.floating-point {
  pointer-events: none;
  user-select: none;
  position: fixed;
  top: 40%;
  left: 50%;
  font-family: var(--second-font);
  color: whitesmoke;
  font-size: 2em;
  font-weight: 700;
  animation: byebye 3s ease-out;
}
