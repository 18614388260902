.counters-container{
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.counter{
    font-family: var(--main-font);
    color: var(--main-color);
    background-color: var(--background-counters-color);
    padding: 3%;
    border-radius: 15px;
    width: 100%;
    display: flex;
}

@media screen and (min-width: 800px) {
    .counters-container {
        flex-direction: row;
        justify-content: space-evenly;
        width: 80%;
    }

    .counter{
        display: flex;
        flex-direction: column;
        width: 25%;
        justify-content: center;
        text-align: center;
    }
}