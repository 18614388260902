.Overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 1;
  display: flex;
}

.settings {
  background-color: rgba(255, 255, 255, 0.9);
  height: 75%;
  width: 90%;
  margin: auto;
  border-radius: 15px;
  border: 1px solid var(--main-color);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  font-family: var(--second-font);
  font-size: 1.2em;
  color: var(--main-color);
  padding: 0.3em;
}

.helper-infos {
  display: flex;
  align-items: center;
}

.helper-image {
  width: 5em;
}

@media screen and (min-width: 800px) {
  .settings {
    margin: 7em;
  }
}
